import React from "react"
import { cmsUrl } from "../../../constants"
import { Link } from "gatsby"

const trimTitle = title => {
  if (title.length > 50) {
    return title.substr(0, 48) + "..."
  }
  return title
}

export default function BlogSecondaryCard({ data }) {
  return (
    <div className="blog-card blog-card--secondary u-margin-top-medium">
      <Link to={`/blog/${data.strapiId}`}>
        {/* <div className="img-container">
          <img src={data.image.publicURL} alt="" />
        </div> */}
        <img src={data.image.publicURL} alt="" />
      </Link>
      <div className="content">
        <p className="date">
          {data.published_at}{" "}
          <span className="tag"> | {data.category.name}</span>
        </p>
        <h1 className="title text-blue">{trimTitle(data.title)}</h1>
        <div className="u-margin-top-small"></div>
      </div>
    </div>
  )
}
