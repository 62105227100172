import React from "react"
import BlogSecondaryCard from "./blogSecondaryCard"

export default function blogList({ articles }) {
  return (
    <>
      <div className="blog-list">
        {articles.map(({ node: article }) => {
          return <BlogSecondaryCard key={article.id} data={article} />
        })}
      </div>
      <div className="rss-feed-container">
        <a href="/blog-rss.xml" target="__blank">
          <button
          style={{fontSize:"2rem"}}
            className="btn btn--contained  btn--small "
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="700"
          >
            RSS Feed
          </button>
        </a>
      </div>
    </>
  )
}
